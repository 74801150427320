<template>
	<!-- @contextmenu.prevent="" -->
	<div id="app" @contextmenu.prevent=""><router-view /></div>
</template>

<script>
export default {
	mounted() {
		var _this = this
		// //初始化加密参数
	 //    this.$Encrypt.initJSEncrypt()
		// 在页面加载时读取sessionStorage
		// if (sessionStorage.getItem('store')) {
		//   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
		// }
		// 在页面刷新时将store保存到sessionStorage里
		// window.addEventListener('beforeunload', () => {
		//   sessionStorage.setItem('store', JSON.stringify(this.$store.state))
		// })
		//读取时间，看看是否过期，一天，过期，然后清除登录信息和token
		// var logintime = localStorage.getItem('logintime')
		// if(logintime){
		// 	var d1 = new Date(parseInt(logintime));//已知时间戳
		// 	var d2 = new Date();//当前时间戳
		// 	var hover=  (parseInt(d2 - d1) / 1000 / 3600) //算出相差的小时
		// 	console.log(hover)
		// 	if(hover>12){
		// 		sessionStorage.setItem('token',"")
		// 		this.$store.commit("SET_ISLOGIN",false)
		// 		//保存
		// 		localStorage.setItem('key_state',"")
		// 		this.$store.commit("SET_Data",{ key: 'sysUserInfo', value:{}})
		// 		localStorage.setItem('loginForm','');
		// 		return;
		// 	}
		// }
		if(sessionStorage.getItem("key_state")&&sessionStorage.getItem("token")){
			console.log("读取缓存登录")
			//存在缓存，赋值
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("key_state"))))
			setTimeout(function(){
				//console.log(_this.$store.state.isLogin)
				if(_this.$store.state.isLogin){
					_this.getCartLength()
				}
			},500)
		}
	},
	methods: {
		
	}
};
</script>
<style lang="scss">
.ptop {
	padding-top: 100px;
}
.mauto{
	margin-left: auto;
}
.mt20{
	margin-top: 20px;
}
.m20{
	margin-left: 20px;
}
.ml20{
	margin-left: 20px;
}
.block10{
	width: 100%;
	height: 10px;
}
.block20{
	width: 100%;
	height: 20px;
}
.block30{
	width: 100%;
	height: 30px;
}
.block40{
	width: 100%;
	height: 40px;
}
.block60{
	width: 100%;
	height: 60px;
}
/deep/ #end{
	display: none;
}
img{
	object-fit: cover;
	display: inline-block;
	border: none;
}
.block45{
	width: 100%;
	height: 45px;
}
.clamp {
	lines: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}
.clamp2 {
	lines: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.clamp3 {
	lines: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.clamp4 {
	lines: 4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
#app {
	height: 100%;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	// color: #2c3e50;
}
.m10{
	margin-left: 10px;
}
// ie隐藏滚动条样式代码：
 html {
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style:none;
    /*火狐下隐藏滚动条*/
    overflow:-moz-scrollbars-none;
}
/*Chrome下隐藏滚动条，溢出可以透明滚动*/
html::-webkit-scrollbar{width:0px}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 138px;
    height: 138px;
    line-height: 138px !important;
    text-align: center;
}
.avatar-uploader .el-avatar {
    width: 138px;
    height: 138px;
    display: block;
}
.avatar-uploader .el-upload__tip{
	margin-top: 1px;
	line-height: 1 !important;
	color: #999999;
}
.imgStyle{
	width: 128px;
	height: 128px;
	display: block;
}
.image-wrap {
	position: relative;
	display: inline-block;
	box-sizing: content-box;
	vertical-align: top;
}
</style>
