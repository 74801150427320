import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	redirect: '/home',
	component: () => import('@/views/index.vue'),
	children: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/home.vue')
		},
		{
			path: '/goodsDetail',
			name: 'goodsDetail',
			component: () => import('@/views/goodsDetail.vue')
		},
		{
			path: '/showCar',
			name: 'showCar',
			component: () => import('@/views/showCar.vue')
		},
		{
			path: '/category',
			name: 'category',
			component: () => import('@/views/category.vue')
		},
		{
			path: '/payPage',
			name: 'payPage',
			component: () => import('@/views/payPage.vue')
		},
		{
			path: '/todayPrice',
			name: 'todayPrice',
			component: () => import('@/views/todayPrice.vue')
		},
		{
			path: '/teamPage',
			name: 'teamPage',
			component: () => import('@/views/teamPage.vue')
		},
		{
			path: '/exchange',
			name: 'exchange',
			component: () => import('@/views/exchange.vue')
		},
		{
			path: '/information',
			name: 'information',
			component: () => import('@/views/information.vue')
		},
		{
			path: '/mine',
			name: 'mine',
			component: () => import('@/views/mine.vue')
		},
		{
			path: '/infoDetail',
			name: 'infoDetail',
			component: () => import('@/views/infoDetail.vue')
		},
		{
			path: '/aboutUs',
			name: 'aboutUs',
			component: () => import('@/views/aboutUs.vue')
		},
		{
			path: '/teamPay',
			name: 'teamPay',
			component: () => import('@/views/teamPay.vue')
		},
		{
			path: '/BlankPage',
			name: 'BlankPage',
			component: () => import('@/views/BlankPage.vue')
		},
		{
			path: '/couponCenter',
			name: 'couponCenter',
			component: () => import('@/views/couponCenter.vue')
		},
		{
			path: '/viewPdf',
			name: 'viewPdf',
			component: () => import('@/views/viewPdf.vue')
		},
		{
			path: '/404',
			name: '404',
			component: () => import('@/views/404.vue')
		}
	]
}]
const router = new VueRouter({
	mode: 'history',
	base: "", //yzc //yzcTest
	routes
})

export default router

var whiteList = ["/","/home","/todayPrice","/teamPage","/exchange","/information","/couponCenter","/aboutUs"]
router.beforeEach(async (to, from, next) => {
	// document.title = getPageTitle(to.meta.title)
	//console.log(to)
	if(whiteList.indexOf(to.path)>-1){
		//console.log("SET_CURPAGE")
		store.commit('SET_CURPAGE', to.name)
	}
	if(to.matched.length>0){
		next()
	}else{
		next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
	}
})
// 切换页面滚动回顶部
router.afterEach((to,from,next) => {
	// console.log("11322")
    window.scrollTo(0,0);
    // 或
    // window.scroll(0, 0);
});
// 处理高版本连续跳转的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
