import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import store from './store'
Vue.prototype.$store = store

Vue.config.productionTip = false
import 'jquery' //引入
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './css/home.scss';
/*媒体查询*/
import './css/main.scss';

/*iconfont*/
import './css/iconfont/iconfont.css';

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import $ from 'jquery'
import http from './common/http.js'
Vue.prototype.$http = http
import mmapi from './common/mmapi.js'
Vue.prototype.$mmapi = mmapi
import Encrypt from './common/Encrypt.js'
Vue.prototype.$Encrypt = Encrypt
import utils from './common/utils.js'
Vue.prototype.$util = utils
import api from './common/api.js'
Vue.prototype.$api = api




Vue.prototype.getCartLength = function(title) {
	return new Promise((resolve, reject) => {
		var _this = this
		var params = {
			currentPage:1,
			pageSize:1,
			userUuid:store.state.uuid,
			userPhone:store.state.phone
		}
		http.post('medicine/medicineDrugsCart/getPage', params).then(function(res) {
			//数据处理
			if (res.code == 200) {
				console.log("购物车长度："+res.data.total)
				Vue.prototype.cartLength = res.data.total;
				resolve(res.data.total)
			} else {
				resolve(false)
			}
		})
	})
}
Vue.prototype.$mmapiRequest = function (options) {
	
	  return new Promise((resolve, reject) => {
	    axios(options)
	      .then(response => {
	        resolve(response.data);
	      })
	      .catch(error => {
	        reject(error);
	      });
	  });
	
	
  //console.log(`Hello,!`)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
