
import Encrypt from './Encrypt.js'
import axios from 'axios'
import store from '../store/index.js'
// import qs from "qs";
//1.引入vue
import Vue from 'vue';
//2.新创建一个vue实例
let v = new Vue();
import router from '../router/index.js'
import {
    Message,
    MessageBox
} from 'element-ui'
/*axios全局配置*/
axios.defaults.withCredentials = true;
//axios.defaults.baseURL = process.env.VUE_APP_BASEURL;//地址
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';//请求头根据自定义需求
axios.defaults.timeout = 60000;///默认请求超时时间

let http = axios.create({
    //请求host
    //baseURL: process.env.VUE_APP_BASEURL,
	//baseURL: '/api',
    headers: {
        isShowloading:false
    }
})

//http request 拦截器
http.interceptors.request.use(
    request => {
		// console.log("打印token")
		// console.log(sessionStorage.getItem('token'))
        //显示加载框
        if (request.headers.isShowloading){
			
		}
        //delete request.headers.isShowloading;
		if(sessionStorage.getItem('token')!=undefined&&sessionStorage.getItem('token')!=null&&sessionStorage.getItem('token')!=""){
			request.headers['Authorization'] = sessionStorage.getItem('token') //'Bearer ' + sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
		}
        if (request.method == 'post' && request.data) {
			// console.log({ ...request.data })
			// console.log(Encrypt.encrypt({ ...request.data }))
			//加密
            //request.data = Encrypt.encrypt({ ...request.data }) //qs.stringify({ ...request.data })
			//console.log(request.data)
        }
        //请求前操作写在这里
		// console.log(process.env.VUE_APP_BASEURL)
		
		//var urlStr=request.url
		//var arr = urlStr.split("/");
		//console.log(arr)
		//if(arr=='drugs'){
			//request.url = process.env.VUE_APP_MMURL+request.url
		//}else{
			request.url = process.env.VUE_APP_BASEURL+request.url
		//}
		
		//request.url = BASEURL+request.url
		// console.log("打印请求头")
		//console.log(request)
        return request
    }, error => {
        // hideLoading();
        return Promise.reject(error)
    });

//http response 拦截器
http.interceptors.response.use(
    response => {
        // hideLoading();
        //请求后续操作写在这里
        if(response.status==200){
			//console.log(response.data)
			if(response.data.code==100000){
				v.$confirm("抱歉，登录超时，请重新登录", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
					.then(() => {
						sessionStorage.setItem('token',"")
						store.commit("SET_ISLOGIN",false)
						//保存
						sessionStorage.setItem('key_state',"")
						store.commit("SET_Data",{ key: 'sysUserInfo', value:{}})
						localStorage.setItem('loginForm','');
						router.push({ path: '/home' })
					})
					.catch(() => {});
				return response.data
			}else{
				return response.data
			}
		}else{
			v.$confirm("请求出错，请稍后再试~", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {}).catch(() => {});
			// alert("请求出错，请稍后再试")
			return response
		}
    },
    error => {
        console.log(error)
		v.$confirm("请求出错，可能网络不佳，请稍后刷新页面再试~", '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {}).catch(() => {});
        // hideLoading();
        return Promise.reject(error.response)
    });
export default http


