import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//为了方便测试，此处用vuex做全局数据
const store = new Vuex.Store({
	state: {
		bsnName:"药中城",
		userInfo: {},
		isLogin: false,//是否登录
		remark: '',
		curpage:"home",
		userType:"purchase",//用户身份 代理商agent 业务代表 business  采购 purchase
		ifSon:false,//是否子账号
		sysUserInfo:{},//代理商agent 业务代表 business  采购 purchase的用户信息
		uuid:"",//用户id
		phone:"",
		oricode:"",//上级邀请码，representUuid:"",//业务、代理的uuid
		nickName:"",//用户头像
		avatarUrl:"",//用户头像
	},
	mutations: {
		SET_Data(state, { key, value }) {
			// console.log(key)
			// console.log(value)
			state[key] = value
		},
		SET_CURPAGE(state, curpage) {
			console.log(curpage)
			state.curpage = curpage
		},
		SET_REMARK(state, remark) {
			state.remark = remark
		},
		SET_USERINFO(state, userInfo) {
			//console.log(userInfo)
			state.userInfo = userInfo
		},
		SET_ISLOGIN(state, isLogin) {
			//console.log(isLogin)
			state.isLogin = isLogin
		}
	}
})

export default store
